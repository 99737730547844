
export const holidays = {
  MD: {
    // Month, Day
    '01/01': 'Confraternização Universal',
    '04/07': 'Paixão de Cristo',
    '04/21': 'Tiradentes',
    '05/01': 'Dia do Trabalho',
    '09/07': 'Independência do Brasil',
    '10/12': 'Nossa Senhora Aparecida',
    '11/02': 'Finados',
    '11/15': 'Proclamação da República',
    '12/25': 'Natal'   
  },
}


export const getFirstUtilDay = date => {
  if (!date) return date
  const weekDay = date.weekday()
  if (weekDay === 0) {
    date.add(1, 'day')
  } else if (weekDay === 6) {
    date.add(2, 'day')
  }
  if (holidays.MD[date.format('MM/DD')]) {
    date.add(1, 'day')
  }
  return date
}

export const parseTypeName = (type) => {
  const typeNames = {
    'cash': 'Dinheiro',
    'debit_card': 'Débito',
    'card': 'Crédito',
    'check': 'Cheque',
    'pix': 'Pix',
    'bank_slip': 'Boleto',
    'debit': 'Débito automático',
    default: type
  };

  return typeNames[type] ?? typeNames.default;
}

export const getBillItemNetValue = (bill, billItem) => {
  const total = getTotal(bill)
  const netValueTotal = getTotalWithTax(bill)
  const diff = total - netValueTotal
  if (diff <= 0) return billItem.final_value
  const netValue = billItem.final_value - ((diff * billItem.final_value) / total)
  // console.log('*** getBillItemNetValue ***** ', netValueTotal, total, diff, Math.round(netValue.toFixed(2)))
  return Math.round(netValue.toFixed(2))
}

const getTotalWithTax = (bill) => {
  if (!bill.payments) {
    return 0
  }
  const total = bill.payments.reduce((acc, payment) => {
    acc += payment.installments.reduce((acc,installment) => {
      acc += installment.amount
      return acc
    }, 0)
    return acc
  }, 0)
  return total
}

const getTotal = (bill) => {
  if (!bill.payments) {
    return 0
  }
  const total = bill.payments.reduce((acc, payment) => {
    acc += payment.amount
    return acc
  }, 0)
  return total
}